<template>
<div v-if="
        getUser.role.slug === 'responsable-relation-client' ||
        getUser.role.slug === 'super-administrateur' ||
        getUser.role.slug === 'rh' ||
        getUser.role.slug === 'charge-daffaires'
      ">

<div v-if="isError">
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/orders/business">Packages</b-breadcrumb-item>
      <b-breadcrumb-item active>Détails</b-breadcrumb-item>
    </b-breadcrumb>
  </div>
  <div v-else>
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/orders/business">Packages</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link
          :to="{
            name: 'business-commande',
            params: { id: this.$route.query.packageId } 
           
          }"
          >Commandes</router-link
        >
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Détails</b-breadcrumb-item>
    </b-breadcrumb>
  </div>
    

    <div v-if="isPageLoading" class="d-flex justify-content-center mb-1" style="padding-top: 30vh">
        <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
    </div>
    <div v-else-if="isError" class="d-flex flex-column justify-content-center mt-5 text-center" style="padding-top: 30vh">
        <h1 class="text-danger">
            <feather-icon icon="XCircleIcon" size="100" />
        </h1>
        <p class="font-medium-1 text-danger font-weight-normal">
            Aucune commande ne correspond à cet identifiant
        </p>
    </div>
    <div v-else>
        <template>
            <div>
                <b-row class="invoice-preview">
                    <!-- Col: Left (Invoice Container) -->
                    <b-col cols="12" xl="9" md="8">
                        <b-card no-body class="invoice-preview-card">
                            <!-- Header -->
                            <b-card-body class="invoice-padding pb-0">
                                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                                    <!-- Header: Left Content -->
                                    <div>
                                        <div class="logo-wrapper">
                                            <b-avatar :text="
                              avatarText(
                                `${directOrder.package.user.first_name} ` +
                                  ` ${directOrder.package.user.last_name}`
                              )
                            " :src="directOrder.package.user.profile_image" size="104px" rounded />
                                        </div>
                                        <h3 class="mt-2 text-success mb-25">
                                            {{ directOrder.package.user.full_name }}
                                        </h3>
                                    </div>

                                    <!-- Header: Right Content -->
                                    <div class="mt-md-0 mt-2">
                                        <h4 class="invoice-title">
                                            Commande
                                            <span class="invoice-number text-success">
                                                #{{ directOrder.id }}
                                            </span>
                                            <b-badge pill :variant="`light-${resolveOrderStatusVariant(
                              directOrder.status,
                              directOrder.placement_cost_paid,
                              directOrder.search_employee
                            )}`" class="text-capitalize font-medium-3">
                                                {{ directOrder.setStatus }}
                                            </b-badge>
                                        </h4>
                                        <div class="invoice-date-wrapper">
                                            <p class="invoice-date-title">Téléphone:</p>
                                            <small class="font-weight-bold text-info font-small-4">
                                                {{ directOrder.package.user.phone_number }}
                                            </small>
                                        </div>
                                        <div class="invoice-date-wrapper">
                                            <p class="invoice-date-title">Email:</p>
                                            <small class="font-weight-bold text-info font-small-4">
                                                {{ directOrder.package.user.email == null ? 'Non renseigé' : directOrder.package.user.email }}
                                            </small>
                                        </div>
                                        <div class="invoice-date-wrapper">
                                            <p class="invoice-date-title">Quartier:</p>
                                            <small class="font-weight-bold text-info font-small-4">
                                                {{ directOrder.address }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>

                            <!-- Spacer -->
                            <hr class="invoice-spacing" />

                            <!-- Invoice Client & Payment Details -->
                            <b-card-body class="pt-0">
                                <h6 class="mb-2 text-center font-medium-2">Détails de la commande</h6>
                                <b-row class="">
                                    <!-- Col: Invoice To -->
                                    <b-col md="6" cols="12">

                                        <p class="card-text mb-25 font-medium-1">
                                            Budget par employé:
                                            <span class="text-info">
                                                {{ directOrder.brut_salary }} FCFA
                                            </span>
                                        </p>
                                        <p class="card-text mb-25 font-medium-1">
                                            Service demandé:
                                            <span class="text-info">{{
                            directOrder.recurring_service.name
                          }}</span>
                                        </p>
                                        <p class="card-text mb-25 font-medium-1">
                                            Date de la demande:
                                            <span class="text-info">
                                                {{ moment(directOrder.created_at).locale("fr").format("llll") }}
                                            </span>
                                        </p>
                                    </b-col>
                                    <b-col md="6" cols="12">
                                        <!-- <p class="card-text mb-25 font-medium-1">
                          Type d'offre:
                          <span class="text-info">
                            {{ directOrder.offer_type.name }}
                          </span>
                        </p> -->
                                        <p class="card-text mb-25 font-medium-1">
                                            Fréquence d'intervention:
                                            <span class="text-info">
                                                {{ directOrder.intervention_frequency }} fois par semaine
                                            </span>
                                        </p>
                                        <p class="card-text mb-0 font-medium-1">
                                            Nombre d'employés recherché:
                                            <span class="text-info">
                                                {{ directOrder.number_of_employees }}
                                            </span>
                                        </p>
                                        <p class="card-text mb-0 font-medium-1">
                                            CNSS:
                                            <span class="text-info">
                                                {{ directOrder.cnss == true ? "Oui" : "Non" }}
                                            </span>
                                        </p>
                                    </b-col>
                                    <!-- Col: Payment Details -->
                                </b-row>
                            </b-card-body>

                        </b-card>
                    </b-col>
                    <!-- Right Col: Card -->
                    <b-col v-if="
                        getUser.role.slug === 'charge-daffaires' ||
                        getUser.role.slug === 'super-administrateur' ||
                        getUser.role.slug === 'responsable-relation-client' 
                        " cols="12" md="4" xl="3" class="invoice-actions">
                        <b-card>

                            <!-- Contrat -->
                            <b-button  variant="outline-primary" block class="mb-75 font-weight-normal font-medium-1 text-white" 
                            :disabled="directOrder.contract == '' "
                            @click="openCustomerContract(directOrder)"

                            >
                                Contrat
                            </b-button>

                            <!-- for list employees -->
                            <b-button v-b-modal.modal-proposed-employee variant="outline-info" block class="mb-75 font-weight-normal font-medium-1" >
                                Liste des employés
                            </b-button>

                            <b-button v-b-modal.modal-deployment-employee :disabled="directOrder.status == 0" variant="outline-success" block class="mb-75 font-weight-normal font-medium-1" >
                                Déployer
                            </b-button>

                            <b-button variant="outline-danger" :disabled="directOrder.status !== 2" block class="mb-75 font-weight-normal font-medium-1" @click="resiliation(directOrder)">
                                Résilier
                            </b-button>

                            <!-- for modify order -->
                            <b-button  variant="primary" class="mb-75 font-weight-normal font-medium-1" block @click="modifyOrder(directOrder)">
                                Modifier
                            </b-button>

                             <!-- for Apply cnss in order -->
                             <!-- <b-button v-if="getUser.role.slug == 'super-administrateur' || getUser.role.slug == 'comptable'" v-b-modal.modal-apply-cnss-employee :disabled="directOrder.cnss == false" variant="outline-success" class="mb-75 font-weight-normal font-medium-1" block >
                                Déclencher Cnss
                            </b-button> -->


                            <!-- Button: DOwnload -->
                            <b-button :disabled=" directOrder.status == 2
                    " v-b-modal.modal-archivage variant="outline-info" class="mb-75 font-weight-normal font-medium-1" block>
                                Archiver le dossier
                            </b-button>
                            <!-- <b-button
                    v-b-toggle.sidebar-invoice-add-payment
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="warning font-weight-normal font-medium-1"
                    class="mb-75"
                    block
                    @click="applyResetDirectOrderAction()"
                  >
                    Réinitialiser
                  </b-button> -->

                            <b-button v-if="
                        getUser.role.slug === 'responsable-relation-client' ||
                        getUser.role.slug === 'super-administrateur'||
                         (getUser.role.slug === 'charge-daffaires')
                      " v-b-toggle.sidebar-invoice-add-payment v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger font-weight-normal font-medium-1" class="mb-75" block @click="applyDeleteBusinessOrderAction()">
                                Supprimer
                            </b-button>
                            <b-button v-if="
                    (getUser.role.slug === 'charge-daffaires' && directOrder.placement_cost_paid === true) 
                  " v-b-modal.modal-send-request v-b-toggle.sidebar-invoice-add-payment v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-danger font-weight-normal font-medium-1" class="mb-75" block @click="applyRequestDeleteDirectOrderAction()">
                                Requette de suppression
                            </b-button>
                        </b-card>
                    </b-col>
                </b-row>
            </div>

            <!--Déploiement des employés -->
            <b-modal id="modal-deployment-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Déploiement des employés">
                <validation-observer #default="{}" ref="orderAssignmentForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalDeployment()" @submit.prevent="applyEmployeeDeployment">
                        <b-form-group label="Choisissez un employé" label-for="role">
                            <validation-provider #default="{ errors }" name="Employé" rules="required">
                                <b-form-select v-if="employeeAffectedOptions.length === 1 && employeeAffectedOptions[0].disabled" id="employee_id" multiple v-model="selectedEmployee" :options="employeeAffectedOptions" />
                                <v-select :selectable="isOptionSelectable"  v-else id="role" multiple v-model="selectedEmployee" :reduce="(employee) => employee" label="full_name" :options="employeeAffectedOptions" >
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Chargement...
                                    </span>
                                    <template #option="{ employee, employee_salary, id , affectation}">
                                       
                                            <b-row>
                                                <b-col md="3">
                                                    <b-avatar size="80px" rounded :src="employee.profile_image" />
                                                </b-col>
                                                <b-col md="9">
                                                    <b-row>
                                                        <b-col>
                                                            Nom & Prénom:
                                                            <span class="text-info">
                                                                {{ employee.full_name }}
                                                            </span>
                                                        </b-col>
                                                        <b-col>
                                                            Age:<span class="text-info"> {{ employee.age }} ans </span>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="">
                                                        <b-col>
                                                            Addresse:
                                                            <span class="text-info">{{ employee.address }}</span>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="">

                                                        <b-col>
                                                            Salaire net:
                                                            <span class="text-info">
                                                                {{ employee_salary }} FCFA
                                                            </span>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                      
                                    </template>
                                    <template #selected-option="employee">
                                        {{ employee.full_name }}
                                    </template>
                                </v-select>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Date de déploiement" label-for="date">
                            <validation-provider #default="{ errors }" name="date de déploiement" rules="required">
                                <flat-pickr v-model="deployBusinessEmployeePayload.employee_deployment_date" class="form-control" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isEmployeeDeploymentLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isEmployeeDeploymentLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Déployer</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalDeployment()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>

                </validation-observer>
            </b-modal>


            <!--Déclencher cnss des employés -->
            <b-modal id="modal-apply-cnss-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Application de la CNSS">
                <validation-observer #default="{}" ref="applyCnssForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalApplyCnss()" @submit.prevent="applyEmployeeCnss">
                        <b-form-group label="Choisissez un employé" label-for="role">
                            <validation-provider #default="{ errors }" name="Employé" rules="required">
                                <b-form-select v-if="employeeActifOptions.length === 1 && employeeActifOptions[0].disabled" id="employee_id" multiple v-model="selectedEmployee" :options="employeeActifOptions" />
                                <v-select :selectable="isOptionActifSelectable" v-else  id="role" multiple v-model="selectedActifEmployee" :reduce="(employee) => employee" label="full_name" :options="employeeActifOptions" >
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Chargement...
                                    </span>
                                    <template #option="{ employee, employee_salary, id , affectation}">
                                       
                                            <b-row>
                                                <b-col md="3">
                                                    <b-avatar size="80px" rounded :src="employee.profile_image" />
                                                </b-col>
                                                <b-col md="9">
                                                    <b-row>
                                                        <b-col>
                                                            Nom & Prénom:
                                                            <span class="text-info">
                                                                {{ employee.full_name }}
                                                            </span>
                                                        </b-col>
                                                        <b-col>
                                                            Age:<span class="text-info"> {{ employee.age }} ans </span>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="">
                                                        <b-col>
                                                            Addresse:
                                                            <span class="text-info">{{ employee.address }}</span>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row class="">

                                                        <b-col>
                                                            Salaire net:
                                                            <span class="text-info">
                                                                {{ employee_salary }} FCFA
                                                            </span>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                      
                                    </template>
                                    <template #selected-option="employee">
                                        {{ employee.full_name }}
                                    </template>
                                </v-select>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        
                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isEmployeeApplyCnssLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isEmployeeApplyCnssLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Appliquer</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalApplyCnss()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>

                </validation-observer>
            </b-modal>

            <!--Résiliation des employés -->
            <b-modal id="modal-suspend-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Résiliation des employés">
                <validation-observer #default="{}" ref="employeeResiliationForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalResiliation()" @submit.prevent="applyResiliationAction">
                        <b-form-group label="Liste des employés" label-for="employee">
                            <validation-provider #default="{ errors }" name="employee" rules="required">
                                <b-form-select v-if="employeeAffectedOptions.length === 1 && employeeAffectedOptions[0].disabled" id="employee_id" multiple v-model="selectedEmployee" :options="employeeAffectedOptions" />
                                <v-select v-else id="role" multiple v-model="selectedEmployee" :reduce="(employee) => employee" label="full_name" :options="employeeAffectedOptions">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Chargement...
                                    </span>
                                    <template #option="{ employee, employee_salary, id }">
                                        <b-row>
                                            <b-col md="3">
                                                <b-avatar size="80px" rounded :src="employee.profile_image" />
                                            </b-col>
                                            <b-col md="9">
                                                <b-row>
                                                    <b-col>
                                                        Nom & Prénom:
                                                        <span class="text-info">
                                                            {{ employee.full_name }}
                                                        </span>
                                                    </b-col>
                                                    <b-col>
                                                        Age:<span class="text-info"> {{ employee.age }} ans </span>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="">
                                                    <b-col>
                                                        Addresse:
                                                        <span class="text-info">{{ employee.address }}</span>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="">

                                                    <b-col>
                                                        Salaire net:
                                                        <span class="text-info">
                                                            {{ employee_salary }} FCFA
                                                        </span>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </template>
                                    <template #selected-option="employee">
                                        {{ employee.full_name }}
                                    </template>
                                </v-select>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Date de résiliation" label-for="date">
                            <validation-provider #default="{ errors }" name="date de résiliation" rules="required">
                                <flat-pickr v-model="payloadResiliation.date" class="form-control" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group label="Raison de la  résiliation" label-for="reason">
                            <validation-provider #default="{ errors }" name="raison de la résiliation" rules="required">
                                <b-form-input id="reason" v-model="payloadResiliation.reason" type="text" :state="errors.length > 0 ? false : null" trim />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isResiliationLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isResiliationLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Résilier</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalResiliation()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>

                </validation-observer>
            </b-modal>
            <!-- Sending Feedback Modal -->
            <b-modal id="modal-send-feedback" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Feedback client">
                <div v-if="isQuizLoading" class="d-flex justify-content-center mb-1" style="padding-top: 30vh">
                    <b-spinner variant="info" style="width: 3rem; height: 3rem" class="mr-1" />
                </div>
                <!-- <validation-observer #default="{}" ref="orderAssignmentForm">
             
              <b-form
                class="p-2"
                autocomplete="off"
                @reset.prevent="hideModalSendFeedback()"
                @submit.prevent="applySendFeedbackAction()"
              >
                <b-form-group
                  label="Résumé de votre discussion avec le  client"
                  label-for="textarea-default"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="feedback"
                    rules="required"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="payload.feedback"
                      :state="errors.length > 0 ? false : null"
                      placeholder=""
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
  
                
                <div class="d-flex mt-2">
                  <b-button
                    :disabled="isFeedbackSending"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    <div v-if="isFeedbackSending">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Soumettre</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                    @click="hideModalSendFeedback()"
                  >
                    Annuler
                  </b-button>
                </div>
              </b-form>
            </validation-observer> -->
            </b-modal>

            <!-- Make comment Modal -->
            <b-modal id="modal-make-comment" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Commentaire sur la commande">
                <validation-observer #default="{}" ref="orderAssignmentForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalSendComment()" @submit.prevent="applySendComment()">
                        <b-form-group label="Commentaire" label-for="textarea-default">
                            <validation-provider #default="{ errors }" name="comment" rules="required">
                                <b-form-textarea id="textarea-default" v-model="payloadComment.comment" :state="errors.length > 0 ? false : null" placeholder="" rows="3" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isMakeComment" variant="primary" class="mr-2" type="submit">
                                <div v-if="isMakeComment">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Soumettre</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalSendComment()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>
            <!-- List des commentaires -->
            <b-modal id="modal-comments" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Commentaires effectuées" size="xl" stacking>
                <b-table ref="refInvoiceListTable" :items="comments" responsive :fields="columnsComments" primary-key="id" :busy="isComments" show-empty empty-text="Liste vide" class="position-relative">
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle" /> &nbsp;
                            <strong>Chargement...</strong>
                        </div>
                    </template>
                    <!-- Commentaire -->
                    <template #cell(comment)="data">
                        <span> {{ data.item.comment }} </span>
                    </template>
                    <!-- Make by -->
                    <template #cell(comment_make_by)="data">
                        <span>
                            {{ data.item.commented_by.full_name}}
                        </span>
                    </template>
                    <!-- Make at -->
                    <template #cell(comment_at)="data">
                        <span>
                            {{ moment(data.item.created_at).locale("fr").format("llll")}}
                        </span>
                    </template>

                    <!-- Column: Actions -->
                    <template #cell(actions)="data" v-if="
        getUser.role.slug === 'charge-daffaires'
      ">
                        <div class="text-nowrap">
                            <b-dropdown variant="link" toggle-class="p-0" no-caret>
                                <template #button-content>
                                    <feather-icon icon="MoreVerticalIcon" size="25" class="align-middle text-center text-info" />
                                </template>

                                <b-dropdown-item v-b-modal.modal-update-comment :id="`update${data.item.id}`" class="cursor-pointer itemActions" @click="applyUpdateCommentAction(data.item)">
                                    <feather-icon icon="EditIcon" class="text-success" size="20" />
                                    <span class="align-middle ml-50">Modifier commentaire</span>
                                </b-dropdown-item>
                                <b-dropdown-item :id="`delete${data.item.id}`" class="cursor-pointer itemActions" @click="applyDeleteComment(data.item)">
                                    <feather-icon icon="TrashIcon" class="text-danger" size="20" />
                                    <span class="align-middle ml-50">Supprimer</span>
                                </b-dropdown-item>

                            </b-dropdown>
                        </div>
                    </template>
                </b-table>
            </b-modal>

            <!-- Update comment Modal -->
            <b-modal id="modal-update-comment" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Modifié le commentaire">
                <validation-observer #default="{}" ref="UpdateComment">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalUpdateComment()" @submit.prevent="applyUpdateComment()">
                        <validation-provider #default="{ errors }" name="commentaire" rules="required">
                            <b-form-group label="Commentaire" label-for="comment" :state="errors.length > 0 ? false : null">
                                <b-form-textarea id="comment" v-model="updateCommentPayload.comment" rows="3" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isUpdateComment" variant="primary" class="mr-2" type="submit">
                                <div v-if="isUpdateComment">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Modifier le commentaire</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>

            <!-- DELETE REQUEST Modal -->
            <b-modal id="modal-send-request" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Demande de suppression">
                <validation-observer #default="{}" ref="deleterequestForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalDeleteRequest()" @submit.prevent="applySendDeleteRequestAction()">
                        <b-form-group label="Renseignez la raison de suppression de la commande" label-for="textarea-default">
                            <validation-provider #default="{ errors }" name="raison" rules="required">
                                <b-form-textarea id="textarea-default" v-model="payloadDelete.reason" :state="errors.length > 0 ? false : null" placeholder="" rows="3" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isDeleteRequestSending" variant="primary" class="mr-2" type="submit">
                                <div v-if="isDeleteRequestSending">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Soumettre</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalSendDeleteRequest()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>

            <!-- List Proposition Modal -->
            <b-modal id="modal-proposed-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Liste des employés" size="xl" stacking>
                <b-table ref="refInvoiceListTable" :items="propositions" responsive :fields="tableColumns1" primary-key="id" :busy="isProposedEmployeesLoading" show-empty empty-text="Liste vide" class="position-relative">
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle" /> &nbsp;
                            <strong>Chargement...</strong>
                        </div>
                    </template>
                    <!-- Column: Invoice Status -->
                    <template #cell(is_rejected)="data">
                        <b-badge pill :variant="`light-${resolvePropositionStatusVariant(data.item.status)}`" class="text-capitalize font-small-3">
                            {{ resolveStatusPropositionText(data.item.status) }}
                        </b-badge>
                    </template>
                    <!-- Salaire -->
                    <template #cell(salary)="data">
                        <span> {{ data.item.employee_salary }} FCFA </span>
                    </template>
                    <!-- Salaire -->
                    <template #cell(comment)="data">
                        <span>
                            {{ data.item.comment }}
                        </span>
                    </template>

                    <!-- Column: employee -->
                    <template #cell(employee)="data">
                        <b-media vertical-align="center">
                            <template #aside>
                                <b-avatar size="32" :src="data.item.employee.profile_image" />
                            </template>
                            <span class="font-weight-bold d-block text-nowrap">
                                {{ data.item.employee.full_name }}
                            </span>
                            <small class="text-success font-small-3">{{
                    data.item.employee.phone_number
                  }}</small>
                        </b-media>
                    </template>
                    <template #cell(apply_Cnss)="data">
                        
                        <span v-if="data.item.applied_cnss == 1"> Oui</span>
                        <span v-else="data.item.applied_cnss == 0"> Non</span>
                    </template>
                    <!-- Column: Employee contract started date -->
                    <template #cell(employee_contrat_started_date)="data">
                        <span v-if="data.item.status === 2">
                            {{
                    moment(data.item.employee_contrat_started_date)
                      .locale("fr")
                      .format("dddd DD MMMM YYYY")
                  }}
                        </span>
                        <span v-else-if="data.item.status === -2">
                            {{
                    moment(data.item.employee_contrat_started_date)
                      .locale("fr")
                      .format("dddd DD MMMM YYYY")
                  }}
                        </span>
                        <span v-else> En attente de déploiement </span>
                    </template>

                    <template #cell(end_contrat_reason)="data">
                        <span class="text-info">
                            {{
                    data.item.end_contrat_reason != null
                      ? data.item.end_contrat_reason
                      : "--------"
                  }}
                        </span>
                    </template>

                    <template #cell(rejection_reason)="data">
                        <span class="text-info">
                            {{
                    data.item.rejection_reason != null
                      ? data.item.rejection_reason
                      : "--------"
                  }}
                        </span>
                    </template>

                    <!-- Column: Employee contract ended date -->
                    <template #cell(employee_contrat_end_date)="data">
                        <span v-if="data.item.status === -2">
                            {{
                    moment(data.item.employee_contrat_end_date)
                      .locale("fr")
                      .format("dddd DD MMMM YYYY")
                  }}
                        </span>
                        <span v-else-if="data.item.status === 2"> Actif </span>
                        <span v-else> En attente de résiliation </span>
                    </template>
                    <!-- Column: Actions -->
                    <template #cell(actions)="data">
                        <feather-icon v-b-modal.modal-action icon="MoreVerticalIcon" class="text-info" size="20" @click="getInfos(data.item)" />
                    </template>
                </b-table>
            </b-modal>

            <b-modal id="modal-action" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Actions sur l'offre" size="sm">
                <b-list-group>
                    <b-list-group-item button :id="`delete${dataItem.id}`" v-b-modal.modal-detail-employee class="cursor-pointer itemActions" @click="applyEmployeeDetailsAction(dataItem)">
                        <feather-icon icon="ListIcon" class="text-info" size="20" />
                        <span class="align-middle ml-50">Détails de l'employé</span>
                    </b-list-group-item>

                    <b-list-group-item button :id="`deploy${dataItem.id}`" class="cursor-pointer itemActions" @click="showUpdateDeploiementDateModal(dataItem)" v-if="dataItem.status == 2">
                        <feather-icon icon="NavigationIcon" class="text-success" size="20" />
                        <span class="align-middle ml-50">Modifier la date de déploiement</span>
                    </b-list-group-item>

                </b-list-group>

            </b-modal>

           <!--- Modal détails des employés-->

        <b-modal id="modal-detail-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc :title="`Détail de l'employé`">
            <div v-if="item == null">
                <span> Chargement ... </span>
                <b-spinner small />
            </div>
            <div class="justify-content-center" v-if="item !== null">
                <b-row>
                    <b-col md="12">
                      <b-row>
                        
                            
                            <b-col>
                              <div class="profile-image-wrapper">
                              <div class="profile-image text-center p-1">
                                <b-avatar
                                  variant="light-primary"
                                  class="badge-minimal"
                                  :src="item.profile_image"
                                  size="204px"
                                  rounded
                                />
                              </div>
                            </div>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white">Nom : </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info"> {{ item.full_name }} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />

                        <b-row>
                            <b-col>
                                <span class="text-white">Addresse: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info"> {{ item.address }} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white">Age: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info"> {{ item.age }} ans</strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white">Niveau: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info"> {{ item.degree }} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white">Nationnalité: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info"> {{ item.nationality }} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white">Situation matrimoniale: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info"> {{ item.marital_status }} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        
                        <hr />
                        <b-row>
                            <b-col>
                                <span class="text-white"> Service fournis: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info">
                                        {{this.servicesFiltres.name}} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white"> Compétences: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info">
                                        {{this.servicesFiltres.pivot.about}} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white"> Expériences professionnelle: </span>
                            </b-col>
                            <b-col>
                                <span>
                                    <strong class="text-info">
                                        {{this.servicesFiltres.pivot.years_of_experience}} </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col>
                                <span class="text-white"> Prétention salariale: </span>
                            </b-col>
                            <b-col>
                                <span v-if="this.servicesFiltres.pivot.salary_pretention !== null ">
                                    <strong class="text-info" >
                                        {{this.servicesFiltres.pivot.salary_pretention}} FCFA</strong>
                                </span>

                                <span v-else>
                                    <strong class="text-info" >
                                        Non renseigné
                                    </strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                        <b-row>
                            <b-col v-if="item.customers.length !== 0 ">
                                <span class="text-white"> Status: </span>
                            </b-col>
                            <b-col >
                                <span >
                                    <strong class="text-info" v-if="item.customers.length !== 0 ">
                                        Occupé chez (<span v-for="customer in item.customers"> {{customer}},</span>)</strong>
                                </span>
                            </b-col>
                        </b-row>
                        <br />
                    </b-col>
                </b-row>
                <br />
            </div>
            <hr />
            <b-button size="sm" variant="success" class="text-center" @click="$bvModal.hide('modal-detail-employee')" >
                Fermer
            </b-button>
        </b-modal>
            <!-- TERMINATE EMPLOYEE CONTRAT-->
            <b-modal id="modal-terminate-employee-contrat" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc :title="`Résiliation du contrat employé`">
                <validation-observer #default="{}" ref="terminateEmployeeContratForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalTerminateEmployeeContrat()" @submit.prevent="applyTerminateEmployeeContractAction()">
                        <validation-provider #default="{ errors }" name="type" rules="required">
                            <b-form-group label="Type de résiliation" label-for="type" :state="errors.length > 0 ? false : null">
                                <v-select v-model="terminateEmployeePayload.type" :options="resiliationTypeOptions" label="label" :reduce="(item) => item.value" />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                    {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="raison du changement" rules="required">
                            <b-form-group label="Raison de la changement" label-for="reason" :state="errors.length > 0 ? false : null">
                                <b-form-textarea id="change-reason" v-model="terminateEmployeePayload.reason" placeholder="Laissez la raison de résiliation" rows="3" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                        <b-form-group label="date de resiliation" label-for="date">
                            <validation-provider #default="{ errors }" name="date de resiliation" rules="required">
                                <flat-pickr v-model="terminateEmployeePayload.date" class="form-control" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isTerminateEmployeeContratLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isTerminateEmployeeContratLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Soumettre</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalChangeMeet()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>

            <!-- New Proposition Modal -->
            <b-modal id="modal-new-proposition" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc :title="`Proposition d'employé`" size="lg">
                <validation-observer #default="{}" ref="newPropositionForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalProposition()" @submit.prevent="applyMakePropositionAction">
                        <b-form-group label="Choisissez un employé" label-for="role">
                            <validation-provider #default="{ errors }" name="pro" rules="required">
                                <b-form-select v-if="employeeOptions.length === 1 && employeeOptions[0].disabled" id="employee_id" v-model="payload.employee_id" :options="employeeOptions" class="custom-select" />

                                <v-select v-else id="role" multiple v-model="selectEmployee" :reduce="(employee) => employee" label="full_name" :options="employeeOptions">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Chargement...
                                    </span>
                                    <template #option="{ comment, employee, employee_salary }">
                                        <b-row>
                                            <b-col md="3" class="offset-md-3">
                                                <b-avatar size="90px" rounded :src="employee.profile_image" />
                                            </b-col>
                                        </b-row>
                                        <br />
                                        <b-row>
                                            <b-col md="10">
                                                <b-row>
                                                    <b-col>
                                                        <span class="text-white">
                                                            Nom & Prénom:
                                                            <strong class="text-info">
                                                                {{ employee.full_name }}
                                                            </strong>
                                                        </span>
                                                    </b-col>
                                                    <br />
                                                    <b-col>
                                                        <span class="text-white">
                                                            Age:
                                                            <strong class="text-info">
                                                                {{ employee.age }} ans
                                                            </strong>
                                                        </span>
                                                    </b-col>
                                                    <br />
                                                </b-row>

                                                <b-row class="">
                                                    <b-col>
                                                        <span class="text-white">
                                                            Situation matrimonial:<strong class="text-info">
                                                                {{ employee.marital_status }}
                                                            </strong>
                                                        </span>
                                                    </b-col>
                                                    <br />
                                                    <b-col>
                                                        <span class="text-white">
                                                            Quartier:
                                                            <strong class="text-info"> {{ employee.address }}</strong>
                                                        </span>
                                                    </b-col>
                                                    <br />
                                                </b-row>
                                                <b-row class="">
                                                    <b-col>
                                                        <span class="text-white">
                                                            Nationnalité:
                                                            <strong class="text-info">
                                                                {{ employee.nationality }}
                                                            </strong>
                                                        </span>
                                                    </b-col>
                                                    <br />
                                                    <b-col>
                                                        <span class="text-white">
                                                            Diplôme:
                                                            <strong class="text-info"> {{ employee.degree }}</strong>
                                                        </span>
                                                    </b-col>
                                                    <b-col>
                                                        <span class="text-white">
                                                            Années d'expérience:
                                                            <strong class="text-info">
                                                                {{
                                      employee.recurring_services[0].pivot
                                        .years_of_experience
                                    }}</strong>
                                                        </span>
                                                    </b-col>
                                                    <b-col>
                                                        <span class="text-white text-wrap">
                                                            Résumé du CV:
                                                            <strong class="text-info">
                                                                {{ employee.recurring_services[0].pivot.about }}</strong>
                                                        </span>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <br />

                                                    <b-col>
                                                        <span class="text-white text-wrap">Commentaire:
                                                            <strong class="text-warning">
                                                                {{
                                      comment != null ? comment : "Non renseigné"
                                    }}</strong></span>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <hr />
                                        <br />
                                    </template>
                                </v-select>

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isMakingPropositionLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isMakingPropositionLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Envoyer</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hideModalProposition()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>

            <!-- DETAILS Proposition Modal -->
            <b-modal id="modal-detail-proposition" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc :title="`Détail du salaire de l'employé`">
                <div v-if="isDetailSalaryLoading">
                    <span> Chargement ... </span>
                    <b-spinner small />
                </div>
                <div class="justify-content-center" v-else>
                    <b-row>
                        <b-col md="12">
                            <b-row>
                                <b-col>
                                    <span class="text-white"> Salaire net : </span>
                                </b-col>
                                <b-col>
                                    <span>
                                        <strong class="text-info"> {{ salary.net_salary }} FCFA</strong>
                                    </span>
                                </b-col>
                            </b-row>
                            <br />
                            <div v-if="salary.cnss === false">
                                <b-row>
                                    <b-col>
                                        <span class="text-white"> Assurance (3%) : </span>
                                    </b-col>
                                    <b-col>
                                        <span>
                                            <strong class="text-info">
                                                {{ salary.assurance_amount }} FCFA</strong>
                                        </span>
                                    </b-col>
                                </b-row>
                                <br />
                            </div>
                            <div v-if="salary.cnss === true">
                                <b-row>
                                    <b-col>
                                        <span class="text-white"> CNSS part patronal (19,4%): </span>
                                    </b-col>
                                    <b-col>
                                        <span>
                                            <strong class="text-info">
                                                {{ salary.cnss_customer_amount }} FCFA</strong>
                                        </span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-white"> CNSS part ouvrière(3,6%): </span>
                                    </b-col>
                                    <b-col>
                                        <span>
                                            <strong class="text-info">
                                                {{ salary.cnss_employee_amount }} FCFA
                                            </strong>
                                        </span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-white"> VPS(0%): </span>
                                    </b-col>
                                    <b-col>
                                        <span>
                                            <strong class="text-info"> {{ salary.vps_amount }} FCFA</strong>
                                        </span>
                                    </b-col>
                                </b-row>
                                <br />
                                <b-row>
                                    <b-col>
                                        <span class="text-white"> ITS: </span>
                                    </b-col>
                                    <b-col>
                                        <span>
                                            <strong class="text-info"> {{ salary.its_amount }} FCFA </strong>
                                        </span>
                                    </b-col>
                                </b-row>
                                <br />
                            </div>
                            <b-row>
                                <b-col>
                                    <span class="text-white">Prestation(20%) </span>
                                </b-col>
                                <b-col>
                                    <span>
                                        <strong class="text-info"> {{ salary.ylomi_fee }} FCFA</strong>
                                    </span>
                                </b-col>
                            </b-row>
                            <br />
                            <hr />
                            <b-row>
                                <b-col>
                                    <span class="text-white"> Budget total du client: </span>
                                </b-col>
                                <b-col>
                                    <span>
                                        <strong class="text-info">
                                            {{ salary.customer_budget }} FCFA</strong>
                                    </span>
                                </b-col>
                            </b-row>
                            <br />
                        </b-col>
                    </b-row>
                    <br />
                </div>
                <hr />
                <b-button size="sm" variant="success" class="text-center" @click="envoi()" :disabled="isDetailSalaryLoading">
                    Envoyer au client
                </b-button>
            </b-modal>
            <!-- Modify Proposition Modal -->
            <b-modal id="modal-modify-proposition" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc :title="`Modifier une proposition`">
                <validation-observer #default="{}" ref="modifyPropositionForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hidePropositionModificationModal" @submit.prevent="applyModifyPropositionAction">
                        <b-form-group label="Choisissez un employé" label-for="role">
                            <validation-provider #default="{ errors }" name="Employé" rules="required">
                                <b-form-select v-if="employeeOptions.length === 1 && employeeOptions[0].disabled" id="employee_id" multiple v-model="selectEmployee" :options="employeeOptions" />
                                <v-select v-else id="role" multiple v-model="selectEmployee" :reduce="(employee) => employee" label="full_name" :options="employeeOptions">
                                    <span slot="no-options" @click="$refs.select.open = false">
                                        Chargement...
                                    </span>
                                    <template #option="{ comment, employee, employee_salary }">
                                        <b-row>
                                            <b-col md="3">
                                                <b-avatar size="80px" rounded :src="employee.profile_image" />
                                            </b-col>
                                            <b-col md="9">
                                                <b-row>
                                                    <b-col>
                                                        <span class="text-info">
                                                            {{ employee.full_name }}
                                                        </span>
                                                    </b-col>
                                                    <b-col>
                                                        <span class="text-info"> {{ employee.age }} ans </span>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="">
                                                    <b-col>
                                                        <span class="text-info">
                                                            {{ employee.marital_status }}
                                                        </span>
                                                    </b-col>
                                                    <b-col>
                                                        <span class="text-info">{{ employee.address }}</span>
                                                    </b-col>
                                                </b-row>
                                                <b-row class="">
                                                    <b-col>
                                                        <span class="text-info"> Commentaire: {{ comment }} </span>
                                                    </b-col>
                                                    <b-col>
                                                        <span class="text-info">
                                                            Salaire net: {{ employee_salary }}
                                                        </span>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </template>
                                    <template #selected-option="employee">
                                        {{ employee.full_name }}
                                    </template>
                                </v-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <validation-provider #default="{ errors }" name="Le Budget du Client" rules="required">
                            <b-form-group label="Budget du client" label-for="employee_salary">
                                <b-form-input id="employee_salary" v-model="customerBudget" type="text" :state="errors.length > 0 ? false : null" trim />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="Salaire de l'Employé" rules="required">
                            <b-form-group label="Salaire de l'employé" label-for="salary">
                                <b-form-input id="employee_amount" v-model="employee_amount" :readonly="getUser.role.slug === 'charge-daffaires'" type="text" :state="errors.length > 0 ? false : null" trim />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider #default="{ errors }" name="La Commission" rules="required">
                            <b-form-group :label="`Commission ylomi  soit ${taux} %`" label-for="commission">
                                <b-form-input id="commission" v-model="ylomi_amount" readonly type="text" :state="errors.length > 0 ? false : null" trim />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                        </validation-provider>
                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isMakingPropositionLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isMakingPropositionLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Modifier</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary" @click="hidePropositionModificationModal()">
                                Annuler
                            </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>

            <!-- Deploy Employee Modal -->
            <b-modal id="modal-deploy-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Déploiement d'employé">
                <validation-observer #default="{}" ref="deployEmployeeForm">
                    <!-- Form -->
                    <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalDeployEmployee()" @submit.prevent="applyDeployEmployeeContractAction()">
                        <b-form-group label="Date de déploiement" label-for="role">
                            <validation-provider #default="{ errors }" name="date de déploiement" rules="required">
                                <flat-pickr v-model="deployEmployeePayload.employee_deployment_date" class="form-control" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- Form Actions -->
                        <div class="d-flex mt-2">
                            <b-button :disabled="isDeployEmployeeLoading" variant="primary" class="mr-2" type="submit">
                                <div v-if="isDeployEmployeeLoading">
                                    <span> Chargement ... </span>
                                    <b-spinner small />
                                </div>
                                <span v-else>Déployer l'employé</span>
                            </b-button>
                            <b-button type="reset" variant="outline-secondary"> Annuler </b-button>
                        </div>
                    </b-form>
                </validation-observer>
            </b-modal>

            <!-- List Desistement Modal -->
            <b-modal id="modal-terminated-employee" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Désistements">
                <b-table ref="refInvoiceListTable" :items="listOfTerminatedEmployees" responsive :fields="terminatedEmployeesColumns" primary-key="id" :busy="isTerminatedEmployees" show-empty empty-text="Liste vide" class="position-relative">
                    <template #table-busy>
                        <div class="text-center text-info my-2">
                            <b-spinner class="align-middle" /> &nbsp;
                            <strong>Chargement...</strong>
                        </div>
                    </template>

                    <!-- Column: Client -->
                    <template #cell(profile_image)="data">
                        <b-media vertical-align="center">
                            <template #aside>
                                <b-avatar rounded :text="avatarText(data.item.recommandation.employee.full_name)" size="42" :src="data.item.employee.profile_image" />
                            </template>
                            <span class="font-weight-bold d-block text-nowrap">
                                {{ data.item.recommandation.employee.full_name }}
                            </span>
                            <small class="text-success font-small-3">
                                {{ data.item.recommandation.employee.phone_number }}</small>
                        </b-media>
                    </template>

                    <!-- Column: Employee contract started date -->
                    <template #cell(employee_contrat_started_date)="data">
                        <span>
                            {{
                    moment(data.item.employee_contrat_started_date)
                      .locale("fr")
                      .format("dddd DD MMMM YYYY")
                  }}
                        </span>
                    </template>

                    <template #cell(end_contrat_reason)="data">
                        <span class="text-info">
                            {{
                    data.item.end_contrat_reason != null
                      ? data.item.end_contrat_reason
                      : "--------"
                  }}
                        </span>
                    </template>

                    <!-- Column: Employee contract startended date -->
                    <template #cell(employee_contrat_end_date)="data">
                        <span>
                            {{
                    moment(data.item.employee_contrat_end_date)
                      .locale("fr")
                      .format("dddd DD MMMM YYYY")
                  }}
                        </span>
                    </template>
                </b-table>
            </b-modal>
        </template>
    </div>
    <b-modal id="modal-archivage" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Raison de l'archivage">
        <validation-observer #default="{}" ref="archivingOrderForm">
            <!-- Form -->
            <b-form class="p-2" autocomplete="off" @reset.prevent="hideModal" @submit.prevent="applyArchiveBusinessOrderAction()">
                <validation-provider #default="{ errors }" name="archivage" rules="required">
                    <b-form-group label="Raison de l'archivage" label-for="archivage" :state="errors.length > 0 ? false : null">
                        <b-form-textarea id="archiving-reason" v-model="archivingPayload.archiving_reason" placeholder="Laissez la raison de l'archivage de cette commande" rows="3" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button :disabled="isArchivingLoading" variant="primary" class="mr-2" type="submit">
                        <div v-if="isArchivingLoading">
                            <span>Chargement ... </span>
                            <b-spinner small />
                        </div>
                        <span v-else>Archiver</span>
                    </b-button>
                    <b-button type="reset" variant="outline-secondary" @click="$bvModal.hide('modal-archivage')">
                        Annuler
                    </b-button>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>

    <!-- Update Deploy Employee Modal -->
    <b-modal id="modal-update-deploiement-date" cancel-variant="outline-secondary" hide-footer centered no-close-on-backdrop no-close-on-esc title="Date de déploiement d'employé">
        <validation-observer #default="{}" ref="deployEmployeeForm">
            <!-- Form -->
            <b-form class="p-2" autocomplete="off" @reset.prevent="hideModalUpdateDeployEmployeeDate()" @submit.prevent="applyUpdateDeploymentEmployee()">
                <b-form-group label="Date de déploiement" label-for="role">
                    <validation-provider #default="{ errors }" name="date de déploiement" rules="required">
                        <flat-pickr v-model="employee_contrat_started_date" class="form-control" />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button :disabled="isUpdateDeployementDateLoading" variant="primary" class="mr-2" type="submit">
                        <div v-if="isUpdateDeployementDateLoading">
                            <span> Chargement ... </span>
                            <b-spinner small />
                        </div>
                        <span v-else>Modifier la date</span>
                    </b-button>
                    <b-button type="reset" variant="outline-secondary"> Annuler </b-button>
                </div>
            </b-form>
        </validation-observer>
    </b-modal>
</div>
<div v-else>
    <not-authorized />
</div>
</template>

  
  
<script>
import {
    BTab,
    BTabs,
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BForm,
    BFormTextarea,
    BFormGroup,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTableLite,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    BCardBody,
    BFormSelect,
    BSpinner,
    BCardText,
    BBreadcrumb,
    BBreadcrumbItem,
    BListGroup,
    BListGroupItem,
    BImg,

    // BPagination,
    BTooltip,
} from "bootstrap-vue";
import {
    avatarText
} from "@core/utils/filter";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {
    ValidationProvider,
    ValidationObserver
} from "vee-validate";
import {
    required,
    alphaNum
} from "@validations";
import {
    mapActions,
    mapGetters
} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import VueWordEditor from "vue-word-editor";
import {
    VueGoodTable
} from "vue-good-table";
// import { params } from 'vee-validate/dist/types/rules/alpha';

export default {
    components: {
        VueWordEditor,
        VueGoodTable,
        BCard,
        // eslint-disable-next-line vue/no-unused-components
        BTab,
        BTableLite,
        BFormTextarea,
        // eslint-disable-next-line vue/no-unused-components
        BTabs,
        BFormSelect,
        BSpinner,
        BForm,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
        // eslint-disable-next-line vue/no-unused-components
        BCardHeader,
        BCardBody,
        BCardText,
        // eslint-disable-next-line vue/no-unused-components
        BAlert,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,

        BLink,
        BBadge,

        BDropdown,
        BBreadcrumb,
        BBreadcrumbItem,
        BDropdownItem,
        // BPagination,
        BTooltip,
        vSelect,
        flatPickr,
        FeatherIcon,
        BListGroup,
        BListGroupItem,
        BImg,

    },

    data() {
        return {
            item: null,
            servicesFiltres: {},
            isDetailEmployeeLoading: false,
            isResiliationLoading: false,
            employee0ptions: [],
            dataItem: {},
            isEmployeeApplyCnssLoading: false,
            isEmployeeDeploymentLoading: false,
            resiliationTypeOptions: [{
                    label: "Résiliation normal",
                    value: 1,
                },
                {
                    label: "Incompétence de l'employé",
                    value: 0,
                },
            ],
            isQuizLoading: false,
            clikedResilier: '',
            updateCommentPayload: {
                comment: '',
                comment_id: ''
            },
            isUpdateComment: false,
            payloadComment: {
                comment: ''
            },
            isMakeComment: false,
            payloadDelete: {
                reason: '',
            },
            isDeleteRequestSending: false,
            isDetailSalaryLoading: true,
            s3Url: "https://docs.google.com/document/d/1cvO2JAilvgBL_3ZiHLjHtPfjbQLso3TGD30E548nMeA",
            salary: {
                assurance_amount: "",
                cnss_customer_amount: "",
                cnss_customer_amount: "",
                cnss_employee_amount: "",
                customer_budget: "",
                its_amount: "",
                vps_amount: "",
                ylomi_fee: "",
            },
            directOrder: null,
            required,
            propositions: [],
            serviceEmployees: [],
            selectEmployee: [],
            employeeOptions: [],
            employeeAffectedOptions: [],
            employeeActifOptions: [],
            selectedEmployee: [],
            selectedActifEmployee: [],
            managedEmployee: [],
            alphaNum,
            isFeedbackSending: false,
            isMakingPropositionLoading: false,
            isProposedEmployeesLoading: false,
            isEmployeesServicesLoading: false,
            isArchivingLoading: false,
            payload: {
                propositions: [],
                recurring_order_id: "",
            },
            archivingPayload: {
                archiving_reason: "",
            },
            employee_amount: null,
            changingEmployee: {
                percentage: "",
                salary: "",
                employee_id: "",
                employee_amount: "",
            },
            customerBudget: 0,
            currentProposition: {},
            employee_contrat_started_date: "",
            tableColumns1: [{
                    key: "employee",
                    label: "Employé",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "salary",
                    label: "Salaire net",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "apply_Cnss",
                    label: "Cnss Appliquée",
                    sortable: true,
                    class: "font-medium-1",
                },

                {
                    key: "employee_contrat_started_date",
                    label: "Date de début du contrat",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "employee_contrat_end_date",
                    label: "Date de fin du contrat",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "end_contrat_reason",
                    label: "Raison de désistement",
                    sortable: true,
                    class: "font-medium-1",
                },

                {
                    key: "is_rejected",
                    label: "Statut",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "actions",
                    class: "font-medium-1"
                },
            ],
            avatarText,
            terminateEmployeePayload: {
                date: null,
                reason: "",
                type: ""
            },
            isTerminateEmployeeContratLoading: false,
            deployEmployeePayload: {
                employee_deployment_date: null,
            },
            isDeployEmployeeLoading: false,
            currentDate: null,
            directOrdersSalaryPaymentByOrder: [],
            employee_id: null,
            listOfTerminatedEmployees: [],
            isTerminatedEmployees: false,
            terminatedEmployeesColumns: [{
                    key: "profile_image",
                    label: "Identité",
                    sortable: true,
                    class: "font-small-3",
                },
                {
                    key: "employee_contrat_started_date",
                    label: "Date de début du contrat",
                    sortable: true,
                    class: "font-small-3",
                },
                {
                    key: "employee_contrat_end_date",
                    label: "Date de fin du contrat",
                    sortable: true,
                    class: "font-small-3",
                },
                {
                    key: "reason",
                    label: "Raison",
                    sortable: true,
                    class: "font-small-3",
                },
            ],
            isPageLoading: true,
            isError: false,
            errorMessage: "",
            isComments: false,
            comments: [],
            columnsComments: [{
                    key: "comment",
                    label: "Commentaires",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "comment_make_by",
                    label: "Commentaire effectué par",
                    sortable: true,
                    class: "font-medium-1",
                },
                {
                    key: "comment_at",
                    label: "Date ",
                    sortable: true,
                    class: "font-medium-1",
                },

                {
                    key: "actions",
                    class: "font-medium-1"
                },
            ],
            deployBusinessEmployeePayload: {
                actifAssignation: [],
                employee_deployment_date: ""
            },
            applyCnssEmployeePayload: {
                actifsEmployeesAssigned: []
            },
            payloadResiliation: {
                actifAssignation: [],
                employee_deployment_date: ""
            },
            disabledOptions: [],
            disabledActifOptions: []
        };
    },

    computed: {
        ...mapGetters("auth", ["getUser"]),
        ...mapGetters("packages", ["getDirectOrder"]),
        ...mapGetters("orders", [
            // 'getDirectOrder',
            "getDirectOrdersSalaryPaymentByOrder",
        ]),

        taux() {
            if (this.ylomi_amount && this.employee_amount) {
                return ((this.ylomi_amount / this.employee_amount) * 100).toFixed(2);
            }
            return 0;
        },
        ylomi_amount() {
            if (this.employee_amount) {
                return this.customerBudget - this.employee_amount;
            }
            return 0;
        },

        /* disabledOptions() {
            return this.employeeAffectedOptions.filter(element => element.status > 0).map(element => element.value);
        }, */
    },

    watch: {
        selectedEmployee(val) {
            console.log(val);
            this.employee_amount = val.employee_desired_salary;
        },
        selectedActifEmployee(val) {
            console.log(val);
            this.employee_amount = val.employee_desired_salary;
        },
        employeeAffectedOptions: {
            deep: true,
            handler() {
                this.updateDisabledOptions();
            }
        },
        employeeActifOptions: {
            deep: true,
            handler() {
                this.updateDisabledActifOptions();
            }
        }
        
    },

    created() {
        /* this.applygetComments() */
        this.applyGetBusinessOrder(this.$route.params.id);
        this.applyGetAffectedEmployeesAction()
        this.applyGetActifEmployeesAction()
        console.log(this.item);
        /*  this.applyGetRecommendedEmployeesAction(this.$route.params.id) */

    },
    mounted(){
        this.updateDisabledOptions();
        this.updateDisabledActifOptions();
    },

    methods: {
        ...mapActions("services", ["getServiceEmployeesAction", "getQuizAction"]),
        ...mapActions("packages", [

            "getDirectPackageOrdersAction",
            "sendFeedbackAction",
            "makePropositionAction",
            "getRecommendedEmployeesAction",
            "getDirectOrderProposedEmployeesAction",
            "getRecommendationEmployeesAction",
            "deleteDirectOrderPropositionAction",
            "getSalaryDetailsAction",
        ]),
        ...mapActions("orders", [
            'getBusinessOrderAction',
            "getAffectedEmployeesAction",
            "getActifEmployeesAction",
            "makeApplyCnssAction",
            "makeBusinessEmployeeDeploymentAction",
            "employeeResiliationAction",
            "getBusinessOrdersAction",
            "deleteBusinessOrderAction",
            "archiveBusinessOrderAction",
            "getArchivedBusinessOrdersAction",
            "changeBusinessEmployeeDeploymentAction",
            "updateCommentAction",
            "deleteCommentAction",
            "storeCommentAction",
            "getCommentsAction",
            "makeEmployeeDeploymentAction",
            "terminateEmployeeContractAction",
            "markDirectOrderAsFinishedAction",
            // 'deleteDirectOrderPropositionAction',
            // 'makePropositionAction',

            "sendDeleteRequestAction",
            "resetOrderAction",
            

            // 'getDirectOrderProposedEmployeesAction',
            "getDirectOrdersSalaryPaymentsByOrderAction",
            "getDirectOrderEmployeesServicesAction",
            // 'getRecommendedEmployeesAction',
            "getDirectOrdersAction",
            "modifyPropositionAction",
            // 'sendFeedbackAction',
            "getArchivedDirectOrdersAction",
            "getTerminatedEmployeesAction",
            "reactiveEmployeeAction"
        ]),
        ...mapActions("professionals", [
        "validateEmployeeAction",
        "validateEmployeeCandidatureAction",
        "getEmployeesDetailsAction",
        "getEmployeesAction",
        "sendEmployeeInFormationAction",
        "suspendEmployeeAction",
        "unsuspendEmployeeAction",
        "getEmployeeNoteAction",
        "rejectEmployeeAction"
        ]),

        openCustomerContract(directOrder) {
        this.$router.push({
          path: '/preview/pdf',
          query: {
            makeActionOnPage: false,
            actionType: null,
            isSigned: true,
            pdf: directOrder.contract,
          },
        })
      },

        updateDisabledOptions() {
            console.log("----------",this.employeeAffectedOptions)
            if(this.employeeAffectedOptions.lenght !== 0){
                this.disabledOptions = this.employeeAffectedOptions.filter(element => element.affectation.status > 0);
            console.log("------OPTINS----",this.disabledOptions)
            }
            
        },

        updateDisabledActifOptions() {
            console.log("----------",this.employeeActifOptions)
            if(this.employeeActifOptions.lenght !== 0){
                this.disabledActifOptions = this.employeeActifOptions.filter(element => element.applied_cnss == 1);
            console.log("------OPTINS----",this.disabledActifOptions)
            }
            
        },

        resiliation(item){
          this.$router.push(
            `/business/order/${item.id}/terminate/employee/contract?packageId=${item.package_id}`
          );
        },

        applyGetBusinessOrder(id) {

            this.getBusinessOrderAction(id)
                .then((response) => {
                    this.isPageLoading = false;
                    console.log("Orderssss", response.data);
                    this.directOrder = response.data;
                    /* this.applyGetRecommendedEmployeesAction(this.$route.params.id); */
                    this.directOrder = this.resolveStatusText(this.directOrder);
                    console.log("Orderssss", this.directOrder);
                    this.directOrder = this.resolveStatusText(this.directOrder);
                    // this.applyGetDirectOrdersSalaryPaymentByOrderAction(
                    // this.directOrder.id,
                    // )
                })
                .catch((error) => {
                    console.log(error);
                    this.isPageLoading = false;
                    this.isError = true;
                    this.errorMessage = Object.values(error.response.data)[0];
                });
            // }
        },

        applyGetAffectedEmployeesAction() {
            this.isProposedEmployeesLoading = true;
            this.employeeAffectedOptions = []

            this.getAffectedEmployeesAction(this.$route.params.id)
                .then((response) => {
                    this.propositions = response.data;

                    console.log(this.propositions, "employés affectééééé")

                    this.isProposedEmployeesLoading = false;

                    if (response.data.length === 0) {
                        this.employeeAffectedOptions.push({
                            value: "",
                            disabled: true,
                            text: "Aucun employé n'a été effectué",
                        });
                    } else {
                        response.data.forEach((element) => {
                            this.employeeAffectedOptions.push({
                                employee_salary: element.employee_salary,
                                full_name: element.employee.full_name,
                                id: element.employee.id,
                                affectation_id: element.id,
                                employee_desired_salary: element.employee_salary,
                                employee: element.employee,
                                affectation : element

                            });
                        })
                    }
                })
                .catch((error) => {
                    this.isProposedEmployeesLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Erreur',
                            icon: 'AlertTriangleIcon',
                            text: error.response.data.message,
                            variant: 'danger',
                        },
                    }, {
                        position: 'top-center',
                    }, )
                });
        },

        applyGetActifEmployeesAction() {
            this.isProposedEmployeesLoading = true;

            
            this.employeeActifOptions = []

            this.getActifEmployeesAction(this.$route.params.id)
                .then((response) => {
                    console.log("EMPLOYE ACTIFS",response.data)

                    this.isProposedEmployeesLoading = false;


                    if (response.data.length === 0) {
                        this.employeeActifOptions.push({
                            value: "",
                            disabled: true,
                            text: "Aucun employé actif sur cette commande ",
                        });
                    } else {
                        response.data.forEach((element) => {
                            this.employeeActifOptions.push({
                                employee_salary: element.employee_salary,
                                full_name: element.employee.full_name,
                                id: element.employee.id,
                                affectation_id: element.id,
                                employee_desired_salary: element.employee_salary,
                                employee: element.employee,
                                affectation : element,
                                applied_cnss: element.applied_cnss

                            });

                            console.log("TEST CNSS", this.employeeActifOptions)
                        })
                    }
                })
                .catch((error) => {
                    this.isProposedEmployeesLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Erreur',
                            icon: 'AlertTriangleIcon',
                            text: error.response.data.message,
                            variant: 'danger',
                        },
                    }, {
                        position: 'top-center',
                    }, )
                });
        },


        applyGetBusinessOrders() {
            this.isReLoadDataRecurringOrders = true
            this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', true)

            this.getBusinessOrdersAction()
                .then(response => {
                    this.isReLoadDataRecurringOrders = false
                    this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
                })
                .catch(error => {
                    this.isReLoadDataRecurringOrders = false
                    this.$store.commit('SET_IS_BUSINESS_ORDERS_LOADING', false)
                })
        },

        hideModalDeployment() {
            this.$bvModal.hide('modal-deployment-employee')
            this.selectedEmployee = []
            this.deployBusinessEmployeePayload = {
                actifAssignation: [],
                employee_deployment_date: "",
            }
        },
                isOptionSelectable(option) {
                    return option.affectation.status == 0;
                    },
                    isOptionActifSelectable(option) {
                    return option.applied_cnss == 0;
                    },

        applyEmployeeDeployment() {
            this.isEmployeeDeploymentLoading = true
            this.deployBusinessEmployeePayload.actifAssignation == this.selectedEmployee.forEach(element => {
                this.deployBusinessEmployeePayload.actifAssignation.push(element.affectation_id)
            })
            this.makeBusinessEmployeeDeploymentAction({
                    orderId: this.$route.params.id,
                    payload: this.deployBusinessEmployeePayload,
                })

                .then((response) => {
                    this.isEmployeeDeploymentLoading = false
                    this.hideModalDeployment();
                    this.getBusinessOrderAction(this.$route.params.id).then(async (response) => {
                        this.businessOrder = response.data;
                        this.businessOrder = this.resolveStatusText(this.businessOrder);
                        this.$store.commit("packages/SET_BUSINESS_ORDERS", this.businessOrder);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Employé déployé avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.applyGetAffectedEmployeesAction()
                        this.applyGetActifEmployeesAction()
                        this.applyGetBusinessOrder(this.$route.params.id)
                        
                        /* this.applyGetAffectedEmployeesAction() */
                    });

                })
                .catch((error) => {
                    this.isEmployeeDeploymentLoading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.response.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.deployBusinessEmployeePayload.actifAssignation = []
                });

        },

        hideModalApplyCnss() {
            this.$bvModal.hide('modal-apply-cnss-employee')
            this.selectedActifEmployee = []
            this.applyCnssEmployeePayload = {
                actifsEmployeesAssigned: [],
            }
        },

        applyEmployeeCnss() {
            this.isEmployeeApplyCnssLoading = true
            this.applyCnssEmployeePayload.actifsEmployeesAssigned == this.selectedActifEmployee.forEach(element => {
                this.applyCnssEmployeePayload.actifsEmployeesAssigned.push(element.affectation_id)
            })
            this.makeApplyCnssAction({
                    orderId: this.$route.params.id,
                    payload: this.applyCnssEmployeePayload,
                })

                .then((response) => {
                    this.isEmployeeApplyCnssLoading = false
                    this.hideModalApplyCnss();
                    this.getBusinessOrderAction(this.$route.params.id).then(async (response) => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Employé déployé avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.applyGetActifEmployeesAction()
                        this.applyGetBusinessOrder(this.$route.params.id)
                        
                        
                    
                    }   ) 
                       

                })
                .catch((error) => {
                    this.isEmployeeApplyCnssLoading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.response.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.deployBusinessEmployeePayload.actifAssignation = []
                });

        },

        hidemodalResiliation() {
            this.$bvModal.hide()
            payloadResiliation = {
                employees: [],
                employee_deployment_date: ""
            }
        },

        applyResiliationAction() {
            this.isResiliationLoading = true
            this.payloadResiliation.employees == this.selectedEmployee.forEach(element => {
                this.payloadResiliation.employees.push(element.affectation_id)
            })
            this.employeeResiliationAction({
                    orderId: this.$route.params.id,
                    payload: this.payloadResiliation,
                })

                .then((response) => {
                    this.isResiliationLoading = false
                    this.hideModalResiliation();
                    this.getBusinessOrderAction(this.$route.params.id).then(async (response) => {
                        this.directOrder = response.data;
                        this.directOrder = this.resolveStatusText(this.directOrder);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Employé résilié avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                        this.applyGetBusinessOrders()
                        this.applyGetAffectedEmployeesAction()
                    });

                })
                .catch((error) => {
                    this.isResiliationLoading = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applyDeleteBusinessOrderAction() {
            this.$swal({
                    title: "Suppression",
                    text: "Êtes-vous sûr de vouloir supprimer cette demande?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.deleteBusinessOrderAction(this.directOrder.id)
                            .then((response) => {})
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                        return await this.applyGetBusinessOrders();
                    },
                })
                .then((response) => {
                    if (response.value) {
                        this.$router.push({ name: "business-commande", params: {id : this.$route.query.packageId} });
                        
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Commande  supprimée avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applySendDeleteRequestAction() {
            this.isDeleteRequestSending = true;
            this.sendDeleteRequestAction({
                    orderId: this.directOrder.id,
                    payload: this.payloadDelete,
                })
                .then(async (response) => {
                    this.isDeleteRequestSending = false;
                    this.hideModalSendDeleteRequest();
                    this.getDirectOrderAction(this.directOrder.id).then(async (result) => {
                        this.directOrder = result.data;
                        this.putRecurringOrdersInStoreAction(this.directOrder);
                    });
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Succès",
                            icon: "CheckIcon",
                            text: response.message,
                            variant: "success",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.getDirectPackageOrdersAction(this.$route.query.packageId);
                })
                .catch((error) => {
                    this.isDeleteRequestSending = false;
                    console.log(error)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.response.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });

        },
        hideModalSendDeleteRequest() {
            this.$bvModal.hide("modal-send-request");
            this.payloadDelete = {
                reason: '',
            };

        },

        openEmployeeContract(proposition) {
            if (proposition.employee_contrat_signature) {
                this.$router.push({
                    path: "/preview/pdf",
                    query: {
                        makeActionOnPage: false,
                        actionType: null,
                        isSigned: true,
                        pdf: proposition.employee_contrat_file_name,
                    },
                });
            } else {
                this.$router.push({
                    path: "/preview/pdf",
                    query: {
                        makeActionOnPage: true,
                        actionType: "signature",
                        isSigned: false,
                        pdf: proposition.employee_contrat_file_name,
                        propositionId: proposition.id,
                    },
                });
            }
        },

        /* applyEmployeeDetailsAction() {
            this.isDetailEmployeeLoading = false,
                this.item = this.dataItem
            this.$bvModal.show('modal-detail-employee')
            this.$bvModal.hide('modal-action')
            console.log("ITEMMM", this.item)
            const services = this.item.employee.recurring_services
            const serviceActuel = this.item.business_recurring_order.recurring_service_id
            services.forEach(service => {
            if (service.id === serviceActuel) {
              this.servicesFiltres = service; 
            }
            console.log(service.id === serviceActuel)
    });
        },
 */

    applyEmployeeDetailsAction(data) {
        console.log(data)
        this.isLoadingEmployeeDetails = true;
        this.getEmployeesDetailsAction(data.employee.id)
            .then((response) => {
            console.log(response.data)
            this.isLoadingEmployeeDetails = false;
            this.item = response.data;
                const services = this.item.recurring_services
                const serviceActuel = this.$route.query.service
                services.forEach(service => {
                this.servicesFiltres = service; 
                
                console.log(this.servicesFiltres)
            })
           

            })
            .catch((error) => {
            this.isLoadingEmployeeDetails = false;
            this.error = true;
            console.log(error)
            /* this.isError = Object.values(error.response.data)[0]; */
            })
    },
        getInfos(item) {
            this.dataItem = item
            console.log(this.dataItem)
        },
        openFeedback(item) {
            this.$router.push({
                path: "/preview/pdf",
                query: {
                    makeActionOnPage: true,
                    actionType: 'modifier',
                    isUpdate: false,
                    pdf: item.feedback,
                    orderId: item.id,
                    packageId: item.package_id,
                    service: item.recurring_service_id
                },
            });
        },
        loadQuiz() {
            /* recurring/services/serviceId/quiz */
            this.isQuizLoading = true
            this.getQuizAction(this.$route.query.service)
                .then(response => {
                    this.isQuizLoading = false
                    console.log(response.data)
                    this.$router.push(
                        `/orders/${this.$route.params.id}/feedback/ca?packageId=${this.$route.query.packageId}&service=${this.$route.query.service}`
                    );

                })
                .catch(error => {
                    this.isQuizLoading = false
                    console.log(error)
                })
        },
        archiveOrder(order) {
            if (order.status != 0) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Attention",
                        icon: "AlertTriangleIcon",
                        text: "Merci de réinitialiser cette commande avant de l'archiver",
                        variant: "warning",
                    },
                }, {
                    position: "top-center",
                });
            } else {
                this.$bvModal.show("modal-archivage");
            }
        },
        ...mapActions("professionals", ["confirmPlacementCostPaidAction"]),

        /** ******************************COMMENTAIRES************************************* */
        applySendComment() {
            this.isMakeComment = true
            this.payloadComment.recurring_order_id = this.$route.params.id
            this.storeCommentAction(this.payloadComment)
                .then(response => {
                    this.isMakeComment = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Succès",
                            icon: "CheckIcon",
                            text: response.data.message,
                            variant: "success",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.hideModalSendComment()
                    this.applygetComments()
                    this.$bvModal.show("modal-comments");

                })
                .catch((error) => {
                    console.log(error);
                    this.isMakeComment = false
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Error",
                            icon: "AlertTriangleIcon",
                            text: error.response.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                })
        },

        applygetComments() {
            this.getCommentsAction(this.$route.params.id)
                .then(response => {
                    this.comments = response.data
                    console.log(response.data)

                })
                .catch(error => {
                    console.log(error)
                })
        },

        applyDeleteComment(comment) {
            this.$swal({
                    title: "Suppression",
                    text: "Êtes-vous sûr de vouloir supprimer ce commentaire ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.deleteCommentAction(comment.id);
                        return await this.applygetComments();
                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.isConfirmed) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Commentaire supprimée",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                    this.$bvModal.show("modal-comments");
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.message.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        applyUpdateCommentAction(item) {
            this.updateCommentPayload = {
                comment: item.comment,
                comment_id: item.id

            }
        },
        applyUpdateComment() {
            this.$refs.UpdateComment.validate().then(success => {
                if (success) {
                    this.isUpdateComment = true
                    this.updateCommentAction({
                            commentId: this.updateCommentPayload.comment_id,
                            payload: this.updateCommentPayload
                        })
                        .then(response => {
                            this.isUpdateComment = false
                            this.applygetComments()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Succès',
                                    icon: 'CheckIcon',
                                    text: response.message,
                                    variant: 'success',
                                },
                            }, {
                                position: 'top-center',
                            }, )
                            this.hideModalUpdateComment()
                        })
                        .catch(error => {
                            console.log(error)
                            this.isUpdateComment = false
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Erreur',
                                    icon: 'AlertTriangleIcon',
                                    text: response.message,
                                    variant: 'danger',
                                },
                            }, {
                                position: 'top-center',
                            }, )
                        })
                }
            })
        },

        /** ******************************Proposition************************************* */
        showUpdateDeploiementDateModal(item) {
            console.log(item);
            this.$bvModal.show("modal-update-deploiement-date");
            this.employee_contrat_started_date = item.employee_contrat_started_date;
            this.currentProposition = item;
        },
        hideModalUpdateDeployEmployeeDate() {
            (this.employee_contrat_started_date = ""),
            (this.isUpdateDeployementDateLoading = false);
            this.$bvModal.hide("modal-update-deploiement-date");
        },
        applyUpdateDeploymentEmployee() {
            this.$bvModal.hide("modal-update-deploiement-date");
            this.isUpdateDeployementDateLoading = true;
            this.$swal
                .fire({
                    title: "Modifier la date de déploiement d'employé",
                    text: "Êtes-vous sûr de vouloir modifier la date de déploiement  cet employé ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: () =>
                        this.changeBusinessEmployeeDeploymentAction({
                            propositionId: this.currentProposition.id,
                            payload: {
                                employee_deployment_date: this.employee_contrat_started_date
                            },
                        })
                        .then((response) => {
                            this.isUpdateDeployementDateLoading = false;
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: response.message,
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.applyGetBusinessOrders()
                        this.applyGetAffectedEmployeesAction()
                        })
                        .catch((error) => {
                            console.log(error)
                            this.$swal.showValidationMessage(`${error.response.data.message}`);
                        }),
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        this.hideModalUpdateDeployEmployeeDate();
                        this.applyGetBusinessOrders()
                        this.applyGetAffectedEmployeesAction()
                    } else {
                        this.hideModalUpdateDeployEmployeeDate();
                    }
                })
                .catch((error) => {
                    this.isUpdateDeployementDateLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        openFile() {
            const file = this.$refs.input.files[0];
            this.$refs.editor.open(
                "https://docs.google.com/document/d/1cvO2JAilvgBL_3ZiHLjHtPfjbQLso3TGD30E548nMeA"
            );
        },
        openInput() {
            this.$refs.input.click();
        },
        envoi() {
            if (this.salary.cnss === true) {
                window.open(`https://wa.me/${
            JSON.parse(localStorage.getItem("clientPackage")).user.phone_number
          }?text=Bonjour Mr/Mme!*${JSON.parse(localStorage.getItem("clientPackage")).user.full_name}*
                      Merci de recevoir ci dessous les détails du budget de votre commande : \n\n salaire net: ${
                        this.salary.net_salary
                      } FCFA, \n\n Assurance(3%): ${this.salary.assurance_amount} FCFA,
                      \n\n Cnss part patronale(19,4%): ${
                        this.salary.cnss_customer_amount
                      } FCFA, \n\n Cnss part ouvrière(3,6%): ${
            this.salary.cnss_employee_amount
          } FCFA,
                    
                      \n\n Prestation: ${
                        this.salary.ylomi_fee
                      } FCFA, \n\n Budget Total par mois: ${
            this.salary.customer_budget
          } FCFA .
                     `);
            } else {
                window.open(`https://wa.me/${
            JSON.parse(localStorage.getItem("clientPackage")).user.phone_number
          }?text=Bonjour Mr/Mme!*${JSON.parse(localStorage.getItem("clientPackage")).user.full_name}*
                      Merci de recevoir ci dessous les détails du budget de votre commande : \n\n salaire net: ${
                        this.salary.net_salary
                      } FCFA, \n\n Assurance(3%): ${this.salary.assurance_amount} FCFA,
                      \n\n Prestation: ${
                        this.salary.ylomi_fee
                      } FCFA, \n\n Budget Total par mois: ${
            this.salary.customer_budget
          } FCFA .
                     `);
            }
        },
        ApplyGetSalaryDetails(item) {
            this.$bvModal.show("modal-detail-proposition");
            this.clickedDetailsSalary = item.id;

            console.log("cliked", this.clickedDetailsSalary);
            this.isDetailSalaryLoading = true
            this.getSalaryDetailsAction(this.clickedDetailsSalary)
                .then((response) => {
                    this.isDetailSalaryLoading = false
                    this.salary = response.data;
                    console.log("DETAILS", this.salary);
                })
                .catch((error) => {
                    this.isDetailSalaryLoading = false
                    console.log("ERROR", error);
                });
            // this.$router.push(`/propositions/recurrent/${item.id}/details`)
        },

        getProposition(item) {
            const recommended_employee = this.employeeOptions.find(
                (el) => el.employee.id == item.employee.id
            );
            this.selectedEmployee = recommended_employee;
            this.currentProposition = item;
            this.directOrder = this.resolveStatusText(this.directOrder);

            this.$bvModal.show("modal-modify-proposition");
            const {
                percentage
            } = this.directOrder.offer_type;
            this.changingEmployee.salary = Math.round(item.salary / (1 + percentage / 100));
        },

        putRecurringOrdersInStoreAction(directOrder) {
            this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", this.resolveStatusText(directOrder));
            this.$router.push({
                name: "admin-orders-recurring-details",
                params: {
                    id: directOrder.id
                },
                query: {
                    packageId: this.$route.query.packageId
                }
            });
        },

        applyGetRecommendedEmployeesAction() {
            const data = {
                recurring_order_id: this.$route.params.id,
            };
            console.log(this.directOrder.id);
            this.getRecommendationEmployeesAction(data).then((response) => {
                console.log(response.data);
                if (response.data.length === 0) {
                    this.employeeOptions.push({
                        value: "",
                        disabled: true,
                        text: "Aucune recommandation n'est effectué",
                    });
                } else {
                    response.data.forEach((element) => {
                        this.employeeOptions.push({
                            comment: element.comment,
                            employee_salary: element.employee_salary,
                            full_name: element.employee.full_name,
                            id: element.employee.id,
                            recommandation_id: element.id,
                            employee_desired_salary: element.employee_salary,
                            employee: element.employee,
                        });
                        // this.proposedEmployeeId = this.resolveStatusPropositionText(this.propositions)
                    });
                }
            });
        },

        showEmployeePropositionModal() {
            if (
                this.directOrder.status === -1 &&
                this.directOrdersSalaryPaymentByOrder.find((el) => el.status === 0)
            ) {
                this.$swal({
                    title: "Attention!",
                    text: "Impossible de faire une  proposition. Cette commande résilié à un paiement non payé.·",
                    icon: "warning",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
            } else {
                this.$bvModal.show("modal-new-proposition");
            }
        },

        applyMakePropositionAction() {
            this.payload.recurring_order_id = this.$route.params.id,
                this.$refs.newPropositionForm.validate().then((success) => {
                    if (success) {
                        this.isMakingPropositionLoading = true;
                        this.selectEmployee.forEach((element) => {
                            this.payload.propositions.push({
                                recommandation_id: element.recommandation_id,
                            });
                        });
                        console.log(this.payload);

                        this.makePropositionAction(this.payload)
                            .then(() => {

                                this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                                    this.directOrder = response.data;
                                    this.directOrder = this.resolveStatusText(this.directOrder);
                                    this.isMakingPropositionLoading = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Succès",
                                            icon: "CheckIcon",
                                            text: "Employé proposé avec succès",
                                            variant: "success",
                                        },
                                    }, {
                                        position: "top-center",
                                    });
                                    this.hideModalProposition();
                                    await this.applyGetDirectOrderProposedEmployeesAction();
                                    this.$bvModal.show("modal-proposed-employee");
                                });
                                this.getDirectPackageOrdersAction(this.$route.query.packageId);
                            })
                            .catch((error) => {
                                console.log(error);
                                this.isMakingPropositionLoading = false;
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Error",
                                        icon: "AlertTriangleIcon",
                                        text: error.response.data.message,
                                        variant: "danger",
                                    },
                                }, {
                                    position: "top-center",
                                });
                            });
                    }
                });
        },

        applyModifyPropositionAction() {
            if (this.customerBudget <= this.employee_amount) {
                this.isMakingPropositionLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error",
                        icon: "AlertTriangleIcon",
                        text: "Le salaire de l'employé doit être toujours strictement inférieur au salaire de l'employé du client",
                        variant: "danger",
                    },
                }, {
                    position: "top-center",
                });
            } else if (this.taux < 15) {
                this.isMakingPropositionLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error",
                        icon: "AlertTriangleIcon",
                        text: "La commission de YLOMI doit être supérieure  ou égale à 15%",
                        variant: "danger",
                    },
                }, {
                    position: "top-center",
                });
            } else if (this.customerBudget <= 0) {
                this.isMakingPropositionLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error",
                        icon: "AlertTriangleIcon",
                        text: "Le salaire de l employé du client doit toujours être strictement supérieur à 0",
                        variant: "danger",
                    },
                }, {
                    position: "top-center",
                });
            } else if (this.employee_amount <= 0) {
                this.isMakingPropositionLoading = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error",
                        icon: "AlertTriangleIcon",
                        text: "Le salaire de l'employé doit toujours être strictement supérieur à 0",
                        variant: "danger",
                    },
                }, {
                    position: "top-center",
                });
            } else {
                this.$refs.modifyPropositionForm.validate().then((success) => {
                    if (success) {
                        this.isMakingPropositionLoading = true;
                        if (this.directOrder.search_employee == 1) {
                            const comission =
                                this.directOrder.offer_type.name === "Offre confort" ?
                                (parseInt(this.changingEmployee.salary) * 10) / 100 :
                                parseInt(this.changingEmployee.salary * 15) / 100;
                            this.changingEmployee.salary = Math.round(
                                parseInt(this.changingEmployee.salary) + comission
                            );
                        }
                        this.changingEmployee.employee_id = this.selectedEmployee.id;
                        this.changingEmployee.employee_amount = this.employee_amount;
                        this.changingEmployee.ylomi_amount = this.ylomi_amount;
                        this.changingEmployee.percentage = this.taux;
                        this.modifyPropositionAction({
                                payload: this.changingEmployee,
                                id: this.directOrder.id,
                                proposedEmployeeId: this.currentProposition.id,
                            })
                            .then(() => {
                                this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                                    this.directOrder = response.data;
                                    this.directOrder = this.resolveStatusText(this.directOrder);
                                    this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", this.directOrder);
                                    this.isMakingPropositionLoading = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: "Succès",
                                            icon: "CheckIcon",
                                            text: "Proposition d'employée modifié avec succès.",
                                            variant: "success",
                                        },
                                    }, {
                                        position: "top-center",
                                    });
                                    this.hidePropositionModificationModal();
                                    await this.applyGetDirectOrderProposedEmployeesAction();
                                    this.$bvModal.show("modal-proposed-employee");
                                });
                                this.getDirectPackageOrdersAction(this.$route.query.packageId);
                            })
                            .catch((error) => {
                                this.isMakingPropositionLoading = false;
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Error",
                                        icon: "AlertTriangleIcon",
                                        text: error.response.data.message,
                                        variant: "danger",
                                    },
                                }, {
                                    position: "top-center",
                                });
                            });
                    }
                });
            }
        },

        applyDeleteDirectOrderPropositionAction(propositionId) {
            this.$swal({
                    title: "Suppression",
                    text: "Êtes-vous sûr de vouloir supprimer cette proposition ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.deleteDirectOrderPropositionAction(propositionId)
                            .then((response) => {})
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                        return await this.applyGetDirectOrderProposedEmployeesAction()

                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.isConfirmed) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Offre supprimée",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                    this.$bvModal.show("modal-proposed-employee");
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applyReactiveEmployeeAction(proposition) {
            this.$swal({
                    title: "Reactivation",
                    text: "Êtes-vous sûr de vouloir réactiver cet employé ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        let payloadReactive = {
                            proposition: proposition.id
                        }
                        await this.reactiveEmployeeAction({
                                propositionId: proposition.id
                            })
                            .then((response) => {})
                            .catch((error) => {
                                this.$swal.showValidationMessage(`${error.response.data.message}`);
                            });
                        return await this.applyGetDirectOrderProposedEmployeesAction()

                    },
                })
                .then((response) => {
                    console.log(response);
                    if (response.isConfirmed) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Employé réactivé avec succès",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                    this.$bvModal.show("modal-proposed-employee");
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        /** ******************************Fin Proposition********************************* */

        modifyOrder(directOrder) {
            this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", directOrder);
            this.$router.push(
                `/orders/business/recurrent/${directOrder.id}/modify?packageId=${directOrder.package_id}`
            );
            console.log(directOrder);
        },

        sendPaiementLinkToCustoomer(id, employeeName) {
            window.open(
                `https://wa.me/${this.directOrder.user.phone_number}?text=Nos salutations! \n La date du  paiement de la prestation de votre ${this.directOrder.recurring_service.name} *${employeeName}* étant aujourdhui, merci de cliquer sur lien ci dessous pour effectuer le paiement. *https://ylomi.net/direct/${this.directOrder.id}/payment/${id}*`
            );
        },

        applyGetDirectOrdersSalaryPaymentByOrderAction(id) {
            this.getDirectOrdersSalaryPaymentsByOrderAction(id)
                .then((response) => {
                    this.directOrdersSalaryPaymentByOrder = response.data;
                })
                .catch((error) => {
                    this.directOrdersSalaryPaymentByOrder = [];
                });
        },

        deployEmployee(item) {
            this.$bvModal.show("modal-deploy-employee");
            this.clickedDeploy = item.id;
            console.log("cliked", this.clickedDeploy);
        },

        applyArchiveBusinessOrderAction() {
            this.$refs.archivingOrderForm.validate().then((success) => {
                if (success) {
                    this.$swal({
                            title: "Archivage",
                            text: "Êtes-vous sûr de vouloir archiver cette commande?",
                            icon: "warning",
                            showCancelButton: true,
                            showLoaderOnConfirm: true,
                            cancelButtonText: "Non",
                            allowOutsideClick: false,
                            confirmButtonText: "Oui",
                            customClass: {
                                confirmButton: "btn btn-warning",
                                cancelButton: "btn btn-outline-primary ml-1",
                            },
                            buttonsStyling: false,
                            preConfirm: async () => {
                                await this.archiveBusinessOrderAction({
                                    directOrderId: this.directOrder.id,
                                    payload: this.archivingPayload,
                                });
                                return (
                                    (await this.getBusinessOrdersAction()) &&
                                    this.getArchivedBusinessOrdersAction()
                                );
                            },
                        })
                        .then((response) => {
                            if (response.value) {
                                this.directOrder = response.value.data;
                                this.directOrder = this.resolveStatusText(this.directOrder);
                                /* this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", this.directOrder), */
                                    this.$router.push('/orders/business/archived');
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: "Succès",
                                        icon: "CheckIcon",
                                        text: "Commande archivée",
                                        variant: "success",
                                    },
                                }, {
                                    position: "top-center",
                                });
                            }
                        })
                        .catch((error) => {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Erreur",
                                    icon: "AlertTriangleIcon",
                                    text: error,
                                    variant: "danger",
                                },
                            }, {
                                position: "top-center",
                            });
                        });
                }
            });
        },

        applyConfirmedPlacementCostPaidAction(id) {
            this.$swal({
                    title: "Confirmer Frais de Placement",
                    text: "Êtes-vous sûr de vouloir valider les frais de placement ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-success",
                        cancelButton: "btn btn-outline-outline ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.confirmPlacementCostPaidAction(this.directOrder.id);
                        return await this.getDirectPackageOrdersAction(this.$route.query.packageId);
                    },
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                            this.directOrder = response.data;
                            this.directOrder = this.resolveStatusText(this.directOrder);
                            this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", this.directOrder);
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Frais de placement confirmé avec succès",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.getDirectPackageOrdersAction(this.$route.query.packageId);
                        });
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applyResetDirectOrderAction() {
            this.$swal({
                    title: "Réinitialisation",
                    text: "Êtes-vous sûr de vouloir réinitialiser cette commande?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () => {
                        await this.resetOrderAction(this.directOrder.id);
                        // eslint-disable-next-line no-return-await
                        return await this.getDirectPackageOrdersAction(this.$route.query.packageId);
                    },
                })
                .then((response) => {
                    if (response.value) {
                        this.$router.go();
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Succès",
                                icon: "CheckIcon",
                                text: "Commande réinitialisée",
                                variant: "success",
                            },
                        }, {
                            position: "top-center",
                        });
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applyDeployEmployeeContractAction() {
            this.$bvModal.hide("modal-proposed-employee");
            this.isDeployEmployeeLoading = false;
            this.$swal
                .fire({
                    title: "Déployement d'employé",
                    text: "Êtes-vous sûr de vouloir déployer cet employé ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: () =>
                        this.makeEmployeeDeploymentAction({
                            propositionId: this.clickedDeploy,
                            payload: this.deployEmployeePayload,
                        })
                        .then((response) => {})
                        .catch((error) => {
                            this.$swal.showValidationMessage(`${error.response.data.message}`);
                        }),
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        this.hideModalDeployEmployee();
                        this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                            this.directOrder = response.data;
                            this.directOrder = this.resolveStatusText(this.directOrder);
                            this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", this.directOrder);
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Employé déployé avec succès",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.applyGetAffectedEmployeesAction()
                        });
                    } else {
                        this.hideModalDeployEmployee();
                    }
                })
                .catch((error) => {
                    this.isDeployEmployeeLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        terminateContrat(item) {
            this.$bvModal.show("modal-terminate-employee-contrat");
            this.clikedResilier = item.id;
            console.log(this.clikedResilier);
        },
        applyTerminateEmployeeContractAction() {
            this.$refs.terminateEmployeeContratForm.validate().then((success) => {
                if (success) {
                    this.isTerminateEmployeeContratLoading = true;
                    /* this.$swal
                      .fire({
                        title: "Résilier le contrat employé",
                        text: "Êtes-vous sûr de vouloir résilier ce contrat de l'employé actif ?",
                        icon: "warning",
                        showCancelButton: true,
                        showLoaderOnConfirm: true,
                        cancelButtonText: "Non",
                        allowOutsideClick: false,
                        confirmButtonText: "Oui",
                        customClass: {
                          confirmButton: "btn btn-warning",
                          cancelButton: "btn btn-outline-primary ml-1",
                        },
                        buttonsStyling: false, 
                        preConfirm: async () =>
                          await this.terminateEmployeeContractAction({
                            propositionId: this.clikedResilier,
                            payload: this.terminateEmployeePayload,
                          })
                            .then((response) => {})
                            .catch((error) => {
                              this.$swal.showValidationMessage(`${error.response.data.message}`);
                            }),
                      })*/
                    this.terminateEmployeeContractAction({
                            propositionId: this.clikedResilier,
                            payload: this.terminateEmployeePayload,
                        })
                        .then((response) => {
                            this.isTerminateEmployeeContratLoading = false;
                            this.getDirectOrderAction(this.directOrder.id).then(async (result) => {
                                console.log(result)
                                this.directOrder = result.data;
                                this.putRecurringOrdersInStoreAction(this.directOrder);
                            });
                            this.applyGetDirectOrderProposedEmployeesAction()
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: response.message,
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.hideModalTerminateEmployeeContrat()
                            // this.$bvModal.hide("modal-terminate-employee-contrat");
                            this.getDirectPackageOrdersAction(this.$route.query.packageId);

                        })
                        .catch((error) => {
                            this.isTerminateEmployeeContratLoading = false;

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Erreur",
                                    icon: "AlertTriangleIcon",
                                    text: error.message,
                                    variant: "danger",
                                },
                            }, {
                                position: "top-center",
                            });
                        });
                }
            });
        },

        applyMarkDirectOrderAsFinished() {
            this.$swal({
                    title: "Clôturer le dossier",
                    text: "Êtes-vous sûr de vouloir clôturer le dossier ?",
                    icon: "warning",
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    cancelButtonText: "Non",
                    allowOutsideClick: false,
                    confirmButtonText: "Oui",
                    customClass: {
                        confirmButton: "btn btn-warning",
                        cancelButton: "btn btn-outline-primary ml-1",
                    },
                    buttonsStyling: false,
                    preConfirm: async () =>
                        await this.markDirectOrderAsFinishedAction(this.directOrder.id),
                })
                .then((response) => {
                    if (response.isConfirmed) {
                        this.getDirectOrderAction(this.directOrder.id).then(async (response) => {
                            this.directOrder = response.data;
                            this.directOrder = this.resolveStatusText(this.directOrder);
                            this.$store.commit("packages/SET_DIRECT_PACKAGE_ORDERS", this.directOrder);
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Succès",
                                    icon: "CheckIcon",
                                    text: "Contrat clôturé avec succès",
                                    variant: "success",
                                },
                            }, {
                                position: "top-center",
                            });
                            this.getDirectPackageOrdersAction(this.$route.query.packageId);
                        });
                    }
                })
                .catch((error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },

        applySendFeedbackAction() {
            this.isFeedbackSending = true;
            if (this.getUser.role.slug === "super-administrateur") {
                this.payload.type = "CA"
            }
            this.sendFeedbackAction({
                    orderId: this.directOrder.id,
                    payload: this.payload,
                })
                .then(async (response) => {
                    this.isFeedbackSending = false;
                    this.hideModalSendFeedback();
                    this.getDirectOrderAction(this.directOrder.id).then(async (result) => {
                        console.log(result)
                        this.directOrder = result.data;
                        this.putRecurringOrdersInStoreAction(this.directOrder);
                    });
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Succès",
                            icon: "CheckIcon",
                            text: response.message,
                            variant: "success",
                        },
                    }, {
                        position: "top-center",
                    });
                    this.getDirectPackageOrdersAction(this.$route.query.packageId);
                })
                .catch((error) => {
                    this.isFeedbackSending = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error.response.data.message,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
        resolvePropositionStatusVariant: (status) => {
            if (status === -2) return "danger";
            if (status === 0) return "warning";
            if (status === 1) return "info";
            if (status === 2) return "success";
            if (status === 3) return "warning";
            return "primary";
        },

        resolveStatusPropositionText: (status) => {
            switch (status) {
                case 0:
                    return "Affecté";
                    break;
                case -2:
                    return "Résilié";
                    break;
                case 2:
                    return "Actif";
                default:
                    return "";
            }

        },

        resolveOrderStatusVariant: (
            status,

        ) => {

            if (status === 0) return "warning";
            if (status === 1) return "info";
            if (status === 2) return "success";
            if (status === -1) return "danger";
            return "primary";
        },

        resolveStatusText(element) {
            if (
                element.status === 0
            ) {
                element.setStatus = "En attente d'affectation";
                return element;
            }

            if (element.status === 1) {
                element.setStatus = "Employé affecté";

                return element;
            }
            if (element.status === 2) {
                element.setStatus = "Actif";
                return element;
            }
            if (element.status === -1) {
                element.setStatus = "Résilié";

                return element;
            }

        },

        openWhatsapp(data) {
            window.open(`https://wa.me/${data.user.phone_number}?text=`);
        },
        hideModal() {
            this.$bvModal.hide("modal-new-proposition");
            this.selectedEmployee = "";
            this.payload = {
                salary: "",
                search_employee: "",
                employee_id: "",
            };
        },

        hideModal2() {
            this.$bvModal.hide("modal-new-proposition-2");
            this.payload = {
                salary: "",
                search_employee: "",
                employee_id: "",
            };
        },

        hidePropositionModificationModal() {
            this.$bvModal.hide("modal-modify-proposition");
            this.selectedEmployee = {};
            this.employee_amount = null;
            this.changingEmployee = {
                salary: "",
                search_employee: "",
                employee_id: "",
            };
        },

        hideModalTerminateEmployeeContrat() {
            this.$bvModal.hide("modal-terminate-employee-contrat");
            this.terminateEmployeePayload = {
                date: "",
                type: "",
                reason: "",
            };
        },

        hideModalSendFeedback() {
            this.$bvModal.hide("modal-send-feedback");
            this.payload = {
                feedback: "",
            };
        },
        hideModalSendComment() {
            this.$bvModal.hide("modal-make-comment");
            this.payloadComment = {
                comment: "",
            };
        },

        hideModalUpdateComment() {
            this.$bvModal.hide("modal-update-comment");
            // this.updateCommentPayload = {
            //           comment: "",
            // };
        },

        hideModalProposition() {
            this.$bvModal.hide("modal-new-proposition");
            this.selectEmployee = [],
                this.payload = {
                    propositions: [],
                    recurring_order_id: "",
                };

        },

        hideModalDeployEmployee() {
            this.$bvModal.hide("modal-deploy-employee");
            this.deployEmployeePayload = {
                employee_deployment_date: "",
            };
        },

        applyGetTerminatedEmployeesAction() {
            this.isTerminatedEmployees = true;
            this.getTerminatedEmployeesAction(this.directOrder.id)
                .then((response) => {
                    this.isTerminatedEmployees = false;
                    this.listOfTerminatedEmployees = response.data.data;
                })
                .catch((error) => {
                    this.isTerminatedEmployees = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Erreur",
                            icon: "AlertTriangleIcon",
                            text: error,
                            variant: "danger",
                        },
                    }, {
                        position: "top-center",
                    });
                });
        },
    },
};
</script>
  
  
<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>
<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss">
  @media print {

      // Global Styles
      body {
          background-color: transparent !important;
      }

      nav.header-navbar {
          display: none;
      }

      .main-menu {
          display: none;
      }

      .header-navbar-shadow {
          display: none !important;
      }

      .content.app-content {
          margin-left: 0;
          padding-top: 2rem !important;
      }

      footer.footer {
          display: none;
      }

      .card {
          background-color: transparent;
          box-shadow: none;
      }

      .customizer-toggle {
          display: none !important;
      }

      // Invoice Specific Styles
      .invoice-preview-wrapper {
          .row.invoice-preview {
              .col-md-8 {
                  max-width: 100%;
                  flex-grow: 1;
              }

              .invoice-preview-card {
                  .card-body:nth-of-type(2) {
                      .row {
                          >.col-12 {
                              max-width: 50% !important;
                          }

                          .col-12:nth-child(2) {
                              display: flex;
                              align-items: flex-start;
                              justify-content: flex-end;
                              margin-top: 0 !important;
                          }
                      }
                  }
              }
          }

          // Action Right Col
          .invoice-actions {
              display: none;
          }
      }
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
  .itemActions :hover {
      background: transparent !important;
      color: white;
  }

  .dark-layout .v-select:not(.vs--single) .vs__selected {
      color: white !important;
  }
</style>
